.nav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navbar {
  padding: 18px 126px;
  box-sizing: border-box;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-link {
  font-family: MontserratRegular;
  font-size: 18px;
}

.nav-link-dark {
  color: #FFFFFF;
}

.nav-link-light {
  color: #141313;
}

.mobile-navbar {
  padding: 27px 41px;
}

@media (max-width: 961px) {
  .navbar {
    padding: 12px 84px;
  }
}
