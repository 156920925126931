.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer-dark {
  color: #FFFFFF;
  background-color: #141313;
}

.footer-light {
  color: #141313;
  background-color: #FFFFFF;
}

.footer-details-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 400px;
  box-sizing: border-box;
}

.footer-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.copyright-info {
  text-align: center;
  display: flex;
  width: 100%;
  padding: 31px 20px;
  box-sizing: border-box;
  justify-content: center;
  font-size: 14px;
}

.copyright-info-dark {
  color: #c9d5e0;
  border-top: 1px solid #c9d5e0;
}

.copyright-info-light {
  color: #141313;
  border-top: 1px solid #C9D5E0;
}

.footer-header {
  font-family: MontserratSemiBold;
}

.footer-link {
  display: block;
  margin: 6px 0;
  text-decoration: none;
}

.footer-link-dark {
  color: #FFFFFF;
}

.footer-link-light {
  color: #141313;
}

.footer-email {
  margin: 0;
  margin-left: 5px;
}

.footer-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (min-width: 961px) and (max-width: 1281px) {
  .footer-details-container {
    padding: 40px 200px;
  }
  .copyright-info {
    padding: 31px 84px;
  }
}

@media (max-width: 961px) {
  .footer-details-container {
    padding: 40px 70px;
  }
  .copyright-info {
    padding: 31px 84px;
  }
  .footer-header {
    font-size: 16px;
  }
  .footer-link {
    font-size: 14px;
  }
  .copyright-info {
    font-size: 14px;
  }
}

@media (max-width: 641px) {
  .copyright-info {
    font-size: 12px;
    border-top: 0;
    padding: 27px;
  }
}
