.privacy-policy-page {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  color: #141313;
  background-color: #F9F8F7;
  justify-content: flex-start;
  overflow: scroll;
}

.privacy-policy-page-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 55%;
  padding-top: 113px;
  padding-bottom: 186px;
  padding-left: 128px;
  font-family: PoppinsRegular;
  font-size: 18;
  color: #48505E;
}

@media (min-width: 641px) and (max-width: 1281px) {
  .privacy-policy-page-main {
    padding-left: 84px;
    padding-right: 128px;
  }
}

@media (max-width: 1281px) {
  .privacy-policy-page-main {
    max-width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 641px) {
  .privacy-policy-page-main {
    padding-left: 41px;
    padding-right: 42px;
  }
}

@media (max-width: 481px) {
  .privacy-policy-page-main {
    padding-left: 37px;
    padding-right: 26px;
  }
}


.nw-terms-title {
  font-family: NotoSerifBold;
  font-size: 50px;
  color: #141313;
}

.nw-terms-section-header {
  font-family: MontserratSemiBold;
  font-size: 24px;
}

.nw-terms-text-bold {
  font-family: PoppinsSemiBold;
}

.nw-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.nw-table-row {
  display: table-row;
}

.nw-table-cell, .nw-table-head {
  display: table-cell;
  padding: 1em;
  border:#141313 1px solid;
}

.nw-table-head {
  font-family: PoppinsSemiBold;
}