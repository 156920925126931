.landing-page {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: flex-start;
}

.landing-main {
  display: flex;
  flex: 1;
  padding: 186px 0;
  padding-top: 186px;
  padding-bottom: 186px;
  padding-right: 275px;
  padding-left: 128px;
  background-image: url("../../../public/images/background-mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
}

.landing-main-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.landing-title-block {
  margin-bottom: 40px;
}

.landing-header {
  font-size: 90px;
  margin: 0;
  font-family: NotoSerifBold;
}

.landing-subtext {
  font-size: 25px;
  margin: 0;
  font-family: PoppinsRegular;
}

@media (min-width: 641px) {
  .landing-main {
    background-image: url("../../../public/images/background.png");
  }
}

@media (min-width: 641px) and (max-width: 1281px) {
  .landing-main {
    padding-left: 84px;
    padding-right: 128px;
  }
}

@media (min-width: 481px) and (max-width: 641px) {
  .landing-main {
    padding-left: 41px;
    padding-right: 42px;
  }
  .landing-header {
    font-size: 64px;
  }
  .landing-subtext {
    font-size: 24px;
  }
}

@media (max-width: 481px) {
  .landing-main {
    padding-left: 37px;
    padding-right: 26px;
  }
  .landing-header {
    font-size: 53px;
  }
  .landing-subtext {
    font-size: 20px;
  }
}
