@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("../public/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("../public/fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url("../public/fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url("../public/fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("../public/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "NotoSerifBold";
  src: local("NotoSerifBold"),
    url("../public/fonts/NotoSerif/NotoSerif-Bold.ttf");
}

body {
  margin: 0;
  font-family: PoppinsRegular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
